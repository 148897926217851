import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import Pricing from "../components/Pricing";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";
import { Container, Nav, Card, CardImg, CardProps, CardImgProps, Accordion, Collapse } from "react-bootstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/custom.css"
import styled from "styled-components"

const StyledHome = styled.div`
  display: flex;
  gap: 0rem;
  position: absolute;
  flex-direction: row;
  margin: 0 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;
const Page1 = ({ }) => (
    <Layout>
        <Container>
            <div className="column">
                <div class="text-center"><StyledHome></StyledHome>
                    <StaticImage
                        src="../img/bg-3.jpg"
                        width={1920}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt=""
                        style={{ margin: `0`, position: "center", transform: "translateY(-1%)" }}
                        layout="constrained"
                    />
                </div>
                <h1 class="text-center">КАТАЛОГ СЕЛЬСКОХОЗЯЙСТВЕННОЙ ТЕХНИКИ «ОЗДОКЕН»</h1>

                <h2 class="text-center background-dark-gray-ozdo has-text-white-ter">СЕЯЛКИ</h2>
                <div class="row justify-content-center align-items-end text-center">

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/hbm1.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="МЕХАНИЧЕСКИЕ ЗЕРНОВЫЕ СЕЯЛКИ HBM
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/seyalki/hbm"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                МЕХАНИЧЕСКИЕ ЗЕРНОВЫЕ СЕЯЛКИ HBM
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/pertum1.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/seyalki/pertum"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/vphe-8.jpeg"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="ПРОПАШНЫЕ СЕЯЛКИ ТОЧНОГО ВЫСЕВА VPHE
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/seyalki/vphe"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                ПРОПАШНЫЕ СЕЯЛКИ ТОЧНОГО ВЫСЕВА VPHE
                            </h5></Link> </div> </div> </div>
                </div>
                <h2 class="text-center background-dark-gray-ozdo has-text-white-ter align-items-center">КУЛЬТИВАТОРЫ</h2>

                <div class="row justify-content-center align-items-end text-center">

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/acm-9.jpeg"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="ACM-9 С ФИКСИРОВАННОЙ РАМОЙ БЕЗ МЕХАНИЗМА ВНЕСЕНИЯ УДОБРЕНИЙ.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/cultivatory/acm-9"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                ACM-9 С ФИКСИРОВАННОЙ РАМОЙ БЕЗ МЕХАНИЗМА ВНЕСЕНИЯ УДОБРЕНИЙ.
                            </h5></Link> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/acm-g9.jpeg"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="ACM-G9 С ФИКСИРОВАННОЙ РАМОЙ С МЕХАНИЗМОМ ВНЕСЕНИЯ УДОБРЕНИЙ.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/cultivatory/acm-9-plus"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                ACM-G9 С ФИКСИРОВАННОЙ РАМОЙ С МЕХАНИЗМОМ ВНЕСЕНИЯ УДОБРЕНИЙ.
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/acm-k9.jpeg"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="ACM-K9 СО СКЛАДНОЙ РАМОЙ БЕЗ МЕХАНИЗМА ВНЕСЕНИЯ УДОБРЕНИЙ.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/cultivatory/acm-k9"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                ACM-K9 СО СКЛАДНОЙ РАМОЙ БЕЗ МЕХАНИЗМА ВНЕСЕНИЯ УДОБРЕНИЙ.
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/acm-kg9.jpeg"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="ACM-KG9 СО СКЛАДНОЙ РАМОЙ С МЕХАНИЗМОМ ВНЕСЕНИЯ УДОБРЕНИЙ.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/cultivatory/acm-kg9"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                ACM-KG9 СО СКЛАДНОЙ РАМОЙ С МЕХАНИЗМОМ ВНЕСЕНИЯ УДОБРЕНИЙ.
                            </h5></Link> </div> </div> </div>



                </div>

                <h2 class="text-center background-dark-gray-ozdo has-text-white-ter align-items-center">ГЛУБОКОРЫХЛИТЕЛИ</h2>
                <div class="row justify-content-center align-items-end text-center">

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/epsom.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="EPSOM ДВУХРЯДНЫЙ. РАБОЧАЯ ГЛУБИНА 35.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/glubokorykhlitely/epsom"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                EPSOM ДВУХРЯДНЫЙ. РАБОЧАЯ ГЛУБИНА 35.
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/epsom-s.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="EPSOM ДВУХРЯДНЫЙ. ДВОЙНАЯ ПРУЖИНА. РАБОЧАЯ ГЛУБИНА 35.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/glubokorykhlitely/epsom-s"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                EPSOM-S ДВУХРЯДНЫЙ. ДВОЙНАЯ ПРУЖИНА. РАБОЧАЯ ГЛУБИНА 35.
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/cgm.jpg"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="CGM РАБОЧАЯ ГЛУБИНА 45 С ГИДРАВЛИЧЕСКИМ ДВОЙНЫМ КАТКОМ.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/glubokorykhlitely/cgm"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                CGM РАБОЧАЯ ГЛУБИНА 45 С ГИДРАВЛИЧЕСКИМ ДВОЙНЫМ КАТКОМ.
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/fdp.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="FDP.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/glubokorykhlitely/fdp"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                FDP
                            </h5></Link> </div> </div> </div>

                </div>
                <h2 class="text-center background-dark-gray-ozdo has-text-white-ter align-items-center">ДИСКОВЫЕ БОРОНЫ</h2>
                <div class="row justify-content-center align-items-end text-center">

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/evodisc.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="EVODISC НАВЕСНАЯ БОРОНА С ГИДРАВЛИЧЕСКОЙ РЕГУЛИРОВКОЙ ГЛУБИНЫ. ЦЕЛЬНАЯ РАМА.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/borony/evodisc"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                EVODISC НАВЕСНАЯ БОРОНА С ГИДРАВЛИЧЕСКОЙ РЕГУЛИРОВКОЙ ГЛУБИНЫ. ЦЕЛЬНАЯ РАМА.
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/evodisc.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="EVODISC T ПРИЦЕПНАЯ БОРОНА С ГИДРАВЛИЧЕСКОЙ РЕГУЛИРОВКОЙ ГЛУБИНЫ. ЦЕЛЬНАЯ РАМА.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/borony/evodisc-t"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                EVODISC T ПРИЦЕПНАЯ БОРОНА С ГИДРАВЛИЧЕСКОЙ РЕГУЛИРОВКОЙ ГЛУБИНЫ. ЦЕЛЬНАЯ РАМА.
                            </h5></Link> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="../img/catalog/evodisc.png"
                            width={392} quality={50} formats={["auto", "webp", "avif"]}
                            alt="EVODISC TK ПРИЦЕПНАЯ БОРОНА С ГИДРАВЛИЧЕСКОЙ РЕГУЛИРОВКОЙ ГЛУБИНЫ. СКЛАДНАЯ РАМА.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="../production/borony/evodisc-tk"
                            class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                                EVODISC TK ПРИЦЕПНАЯ БОРОНА С ГИДРАВЛИЧЕСКОЙ РЕГУЛИРОВКОЙ ГЛУБИНЫ. СКЛАДНАЯ РАМА.
                            </h5></Link> </div> </div> </div>


                </div>



            </div>
        </Container>
    </Layout>
)

export default Page1
